import _ from 'lodash';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';

const CardH = dynamic(import('src/components/cards/card-h'));
const Carousels = dynamic(import('src/components/carousels'));
const CardHighlight = dynamic(import('src/components/cards/card-h-highlight'));
const CardFullHighlight = dynamic(import('src/components/cards/card-full-highlight'));

const SectionFull = ({ sectionId = 'section-1', data = [], eventDataLayer }) => {
  if (_.isEmpty(data)) return null;
  const router = useRouter();

  return (
    <>
      {!_.isEmpty(data) && (
        <SectionFullWrapper id='section-1'>
          {!_.isEmpty(data) && (
            <Carousels dots={false} slideShow={1} pauseOnHover={false} autoplaySpeed={6000}>
              {data.map((item, index) => (
                <CardFullHighlight
                  key={index}
                  data={item}
                  // showBlurb={true}
                  pinTag={true}
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_INDEX,
                    router: router?.pathname,
                    section: sectionId,
                    data: {
                      title: item?.title,
                      heading: 'hightligth',
                      carousel: index + 1
                    }
                  }}
                />
              ))}
            </Carousels>
          )}
          <div>
            {/* --------------------------------- DESKTOP -------------------------------- */}
            <MediaQuery minWidth={768}>
              <div className='position-absolute sub-carosels-card-h'>
                <div className='bg-sub-card-h-highlight'>
                  <Carousels slideShow={3} mobileSlideShow={3} pauseOnHover={false} autoplaySpeed={6000}>
                    {data.map((item, index) => (
                      <CardHighlight
                        key={index}
                        data={item}
                        //! DATALAYER
                        eventDataLayer={{
                          type: DATALAYER_TYPE.TRACK_INDEX,
                          router: router?.pathname,
                          section: sectionId,
                          data: {
                            title: item?.title,
                            heading: 'hightligth',
                            carousel: index + 1
                          }
                        }}
                      />
                    ))}
                  </Carousels>
                </div>
              </div>
            </MediaQuery>
          </div>
          {/* --------------------------------- MOBILE -------------------------------- */}
          <div className='show-desktop'>
            <MediaQuery maxWidth={767}>
              {data.map((item, index) => (
                <CardH
                  key={index}
                  data={item}
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_INDEX,
                    router: router?.pathname,
                    section: sectionId,
                    data: {
                      title: item?.title,
                      heading: 'hightligth',
                      carousel: index + 1
                    }
                  }}
                />
              ))}
            </MediaQuery>
          </div>
        </SectionFullWrapper>
      )}
    </>
  );
};

const SectionFullWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 auto 38px auto;
  @media (min-width: 1224px) {
    max-width: 1300px;
    width: 100%;
    /* padding-left: 30px;
    padding-right: 30px; */
  }

  .custom-carousel {
    padding: 0;
  }
  .card-full-thumb {
    .resolution-image img {
      z-index: -1;
    }
  }
  .card-full-content {
    display: none;
  }
  @media (max-width: 475px) {
    margin-bottom: 15px;
    .card-full-content {
      display: block;
    }
    .card-content {
      padding: 0 8px;
    }
    .heading {
      margin-bottom: 25px;
    }
  }
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    height: 600px;
  }
  @media (max-width: 475px) {
    .no-data {
      height: auto;
    }
  }

  .sub-carosels-card-h {
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 30px;
    background-color: #000000b3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    @media (min-width: 1224px) {
      right: 0;
    }
    .slick-current {
      .card-h {
        .sub-text-hlghlight {
          color: #ffe000;
          width: fit-content;
          font-weight: 500;
        }
        h2,
        span,
        svg,
        .pageview {
          color: #ffe000 !important;
        }
      }
    }
    .slick-track {
      display: flex;
      flex-direction: column;
      .slick-slide {
        width: 100% !important;
      }
    }
  }
`;

export default SectionFull;
